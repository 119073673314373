import { Backups } from "../types/backup";

/**
 * Shopifyのテーマバックアップを整理して、公開中、非公開、削除済みの順に配列として返します。
 *
 * @param {Backups[]} shopifyThemes - `Backups` オブジェクトの配列。各オブジェクトには複数のテーマ情報が含まれています。
 * @returns {Backups[]} - 分類されたバックアップの配列。最初に公開中、次に非公開、最後に削除済みのテーマが返されます。
 */
export const sortBackups = (shopifyThemes: Backups[]): Backups[] => {
  const published: Backups[] = [];
  const unpublished: Backups[] = [];
  const deleted: Backups[] = [];

  shopifyThemes.forEach((shopifyTheme) => {
    const { themes } = shopifyTheme;
    const mainTheme = themes[0].shopify_role === "main";
    const unpublishedTheme = themes[0].shopify_role === "unpublished";
    const deletedTheme = themes[0].theme_deleted;

    if (mainTheme && !deletedTheme) {
      published.push(shopifyTheme);
    } else if (unpublishedTheme && !deletedTheme) {
      unpublished.push(shopifyTheme);
    } else {
      deleted.push(shopifyTheme);
    }
  });

  return [...published, ...unpublished, ...deleted];
};
