import "./style.scss";
import { ThemeWithMemo } from "../../types/theme";
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";
// import { DeleteButton } from "../deleteButton";
import { Store } from "../../types/store";
import { ShopifyThemeBackup } from "../shopifyThemeBackup";

type Props = {
  sessionMaterials: {
    setSessionToken: () => Promise<string>;
    store: Store;
    host: string;
  };
  shopifyTheme: {
    shopify_id: string;
    shopify_name: string;
    created_at: Date;
    memo: string;
    themes: ThemeWithMemo[];
  };
  onRewindStatus: boolean;
  setOnRewindStatus: (data: boolean) => void;
  onBackupStatus: boolean;
};

export const ShopifyThemeBackups: React.FC<Props> = ({
  sessionMaterials: { setSessionToken, store, host },
  shopifyTheme,
  onRewindStatus,
  setOnRewindStatus,
  onBackupStatus,
}) => {
  const sessionMaterials = {
    setSessionToken,
    store,
    host,
  };

  const [toggleStatus, setToggleStatus] = useState<boolean>(false);

  const toggleList = () => {
    setToggleStatus(!toggleStatus);
  };

  const navigate = (url: string) => {
    window.open(url, "_blank");
  };

  const storeUrl = new URL(sessionMaterials.store.base_url);
  const storeName = storeUrl.hostname.split(".")[0];

  return (
    <div className="shopifyThemeBackups">
      <div className="shopifyThemeBackups__head" onClick={toggleList}>
        <div className="shopifyThemeBackups__head__left">
          <div className="shopifyThemeBackups__head__left--name">
            {shopifyTheme.shopify_name}
          </div>
          {shopifyTheme.themes[0].theme_deleted ? (
            <div className="shopifyThemeBackups__head__left--deleted">
              削除済みテーマ
            </div>
          ) : (
            ""
          )}
          {shopifyTheme.themes[0].shopify_role === "main" ? (
            <div className="shopifyThemeBackups__head__left--published">
              公開中
            </div>
          ) : (
            ""
          )}
          <button
            aria-label="コード編集画面へ遷移"
            className="shopifyThemeBackups__head__left--navigate"
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `https://admin.shopify.com/store/${storeName}/themes/${shopifyTheme.shopify_id}`
              );
            }}
          >
            <img src="/Icon/navigate_code.svg" alt="navigate_code" />
          </button>
          <button
            aria-label="カスタマイズ画面へ遷移"
            className="shopifyThemeBackups__head__left--navigate"
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `https://admin.shopify.com/store/${storeName}/themes/${shopifyTheme.shopify_id}/editor`
              );
            }}
          >
            <img src="/Icon/navigate_customize.svg" alt="navigate_code" />
          </button>
        </div>
        <button
          className={`shopifyThemeBackups__head__right ${
            toggleStatus ? "shopifyThemeBackups__head__right--closed" : ""
          }`}
          onClick={toggleList}
        >
          <IoIosArrowDown size={24} />
        </button>
      </div>
      <ul
        className={
          toggleStatus
            ? "shopifyThemeBackups__list"
            : "shopifyThemeBackups__list--hidden"
        }
      >
        {shopifyTheme.themes.map((theme, index) => (
          <ShopifyThemeBackup
            sessionMaterials={sessionMaterials}
            themeBackup={theme}
            onRewindStatus={onRewindStatus}
            setOnRewindStatus={setOnRewindStatus}
            key={index}
            onBackupStatus={onBackupStatus}
          />
        ))}
      </ul>
    </div>
  );
};
