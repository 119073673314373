import LogRocket from "logrocket";

import React from "react";
import "./style.scss";
import { Buffer } from "buffer";

import createApp, {
  AppBridgeState,
  ClientApplication,
} from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { getSessionToken } from "@shopify/app-bridge-utils";

import { Store } from "../../types/store";
// import { CreateBackup } from "../../components/createBackup";
import { Backups } from "../../types/backup";
import { OnBackup } from "../../components/onBackup";
import { ListBackupsComponentMemo } from "../../components/listBackups";
// import { ShopifyTheme } from "../../types/theme";

interface SessionMaterials {
  setSessionToken: () => Promise<string>;
  store: Store;
  host: string;
}

export default class HomeComponent extends React.Component<Record<string, never>> {
  public state = {
    store: null as null | Store,
    backups: [] as Backups[],
    underBackup: false,
    app: null as null | ClientApplication<AppBridgeState>,

    host: null as null | string,
    sessionMaterials: null as null | SessionMaterials
  };

  public async prepareStates(): Promise<void> {
    const params = new URLSearchParams(window.location.search);
    const shop = params.get("shop") ?? "";
    const host = Buffer.from(shop).toString("base64");

    this.setState({ host });

    const createAppHost = params.get("host") ?? "";

    const store = await fetch(
      `${
        process.env.REACT_APP_LAMBDA_ENDPOINT as string
      }/checkInstallation?host=${host}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "accept": "application/json",
          "ngrok-skip-browser-warning": "1",
        }
      }
    )
      .then((res) => res.json())
      .then((data: { store: Store | null }) => data.store);
    if (store) {
      const app = createApp({
        apiKey: process.env.REACT_APP_SHOPIFY_API_KEY ?? "",
        host: createAppHost,
      });

      const sessionMaterials = {
        setSessionToken: () => getSessionToken(app),
        store: store as Store,
        host: host
      } as SessionMaterials;

      this.setState({
        sessionMaterials: sessionMaterials,
        app: app,
        store: store
      });

      return;
    }

    const state = Math.round(Math.random() * 255).toString(16);

    if (window.top === window.self) {
      window.location.assign(
        `https://${shop}/admin/oauth/authorize?client_id=${
          process.env.REACT_APP_SHOPIFY_API_KEY ?? ""
        }&scope=read_themes,write_themes&redirect_uri=${
          process.env.REACT_APP_HOST as string
        }/oauth&state=${state}&grant_options[]=value`
      );

      return;
    }

    Redirect.create(
      createApp({
        apiKey: process.env.REACT_APP_SHOPIFY_API_KEY ?? "",
        host: createAppHost,
      })
    ).dispatch(
      Redirect.Action.REMOTE,
      `https://${shop}/admin/oauth/authorize?client_id=${
        process.env.REACT_APP_SHOPIFY_API_KEY ?? ""
      }&scope=read_themes,write_themes&redirect_uri=${
        process.env.REACT_APP_HOST as string
      }/oauth&state=${state}&grant_options[]=value`
    );
  }

  public componentDidMount(): void {
    LogRocket.init(process.env.REACT_APP_LOGROCKET ?? "");

    this.prepareStates();
  }

  public render(): React.ReactNode {
    if (!this.state.app || !this.state.store || !this.state.sessionMaterials || !this.state.host) {
      return null;
    }

    const app = this.state.app;
    const store = this.state.store;
    const sessionMaterials = this.state.sessionMaterials;
    const host = this.state.host;

    return (
      <div className="home">
        {/* <CreateBackup
          setSessionToken={() => getSessionToken(app)}
          setOnBackupStatus={setOnBackupStatus}
          store={store}
          themes={themes}
          setThemes={setThemes}
        /> */}
        {this.state.underBackup && (
          <OnBackup
            setSessionToken={() => getSessionToken(app)}
            store={store}
            host={host}
            backup={this.state.backups}
            setOnBackup={(backups) => this.setState({ backups })}
            setOnBackupStatus={(underBackup) => this.setState({ underBackup })}
          />
        )}
        <ListBackupsComponentMemo
          sessionMaterials={sessionMaterials}
          setOnBackup={(backups) => this.setState({ backups })}
          setOnBackupStatus={(underBackup) => this.setState({ underBackup })}
        />
      </div>
    );
  }
}
