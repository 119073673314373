import React from "react";
import "./style.scss";
import { Backups } from "../../types/backup";
import { Store } from "../../types/store";
import { ThemeWithMemo } from "../../types/theme";
import { ShopifyThemeBackups } from "../shopifyThemeBackups";
import { sortBackups } from "../../utils/sortBackups";

export interface ListBackupsComponentProps {
  sessionMaterials: {
    setSessionToken: () => Promise<string>;
    store: Store;
    host: string;
  };
  setOnBackup: (data: Backups[]) => void;
  setOnBackupStatus: (data: boolean) => void;
};

/**
 * 2024-03-31 [ando]: コンポーネントに書き換え、再描画時のAPIスパムを修正。
 */
export default class ListBackupsComponent extends React.Component<ListBackupsComponentProps> {
  public state = {
    backupThemes: [] as Backups[],
    initializing: false,
    rewinding: false,
    underBackup: false
  };

  protected async updateBackupThemes(): Promise<void> {
    const sessionToken = await this.props.sessionMaterials.setSessionToken();
    const backups = await fetch(
      `${
        process.env.REACT_APP_LAMBDA_ENDPOINT as string
      }/stores/${this.props.sessionMaterials.store.id.toString()}/themes?host=${this.props.sessionMaterials.host}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "X-SHOPIFY-TOKEN": sessionToken,
        },
      }
    )
      .then((res) => res.json())
      .then((data: { backups: Backups[] }) => data.backups);

    const sortedBackups = sortBackups(backups);
    this.setState({ backupThemes: sortedBackups });

    // backupThemesが空の場合は初期化中、5秒後に再度リストを取得する
    if (backups.length === 0) {
      this.setState({ initializing: true });
      setTimeout(this.updateBackupThemes.bind(this), 5000);

      return;
    } else {
      this.setState({ initializing: false });
    }

    const unCompletedBackups = backups.filter(
      (backup) => backup.completed_at === null
    );

    this.props.setOnBackup(unCompletedBackups);
    this.props.setOnBackupStatus(unCompletedBackups.length > 0);

    this.setState({ underBackup: unCompletedBackups.length > 0 });

    if (unCompletedBackups.length !== 0) {
      return;
    }

    // ando: ここから下はクライアントでやるべきではない気がする
    let state = await fetch(
      `${
        process.env.REACT_APP_LAMBDA_ENDPOINT as string
      }/backups/themes/rename?host=${this.props.sessionMaterials.host}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "X-SHOPIFY-TOKEN": sessionToken,
        },
      }
    )
      .then((res) => res.json())
      .then((data: { message: 'changed' | 'notChanged'}) => data.message);

    if (state === 'changed') {
      setImmediate(this.updateBackupThemes.bind(this));
      return;
    }

    state = await fetch(
      `${
        process.env.REACT_APP_LAMBDA_ENDPOINT as string
      }/backups/themes/deleted?host=${this.props.sessionMaterials.host}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "X-SHOPIFY-TOKEN": sessionToken,
        },
      }
    )
      .then((res) => res.json())
      .then((data: { message: 'changed' | 'notChanged'}) => data.message)

    if (state === 'changed') {
      setImmediate(this.updateBackupThemes.bind(this));
      return;
    }
  }

  protected async createInitialBackup(): Promise<void> {
    fetch(`${process.env.REACT_APP_LAMBDA_ENDPOINT as string}/sqs`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        storeId: this.props.sessionMaterials.store.id,
        type: "appInstallation",
      }),
    })
    .then((res) => res.json())
    .catch((err) => console.log(err));
  }

  public componentDidMount(): void {
    console.log('componentDidMount listBackups');
    this.updateBackupThemes();

    if (!this.props.sessionMaterials.store.is_first_install) {
      this.createInitialBackup();
    }
  }

  public render(): React.ReactNode {
    return (
      <div className="listBackups">
        <div className="listBackups__header">
          <div className="listBackups__header__title">バックアップ一覧</div>
        </div>
        <div className="listBackups__body">
          <div>
            {this.state.initializing && (
              <div className="listBackups__popup">
                <div className="listBackups__popup__title">初回バックアップ中</div>
                <small className="listBackups__popup__small">初回バックアップ中のためしばらくお待ちください。</small>
              </div>
            )}
            {this.state.backupThemes
              .flatMap((backupTheme) => ({
                ...backupTheme.themes[0],
                memo: backupTheme.memo,
              }))
              .reduce((acc, theme) => {
                let existence = false;
                const newThemes = acc.map((prev) => {
                  if (prev.shopify_id === theme.shopify_id) {
                    existence = true;
                    return { ...prev, themes: [...prev.themes, theme] };
                  } else {
                    return prev;
                  }
                });
                if (existence) {
                  return newThemes;
                } else {
                  return [
                    ...newThemes,
                    {
                      shopify_id: theme.shopify_id,
                      shopify_name: theme.shopify_name ?? "",
                      created_at: theme.created_at,
                      memo: theme.memo,
                      themes: [theme],
                    },
                  ];
                }
              }, [] as { shopify_id: string; shopify_name: string; created_at: Date; memo: string; themes: ThemeWithMemo[] }[])
              .map((shopifyTheme, index) => (
                <ShopifyThemeBackups
                  sessionMaterials={this.props.sessionMaterials}
                  shopifyTheme={shopifyTheme}
                  key={index}
                  onRewindStatus={this.state.rewinding}
                  setOnRewindStatus={(rewinding: boolean) => this.setState({ rewinding })}
                  onBackupStatus={this.state.underBackup}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export const ListBackupsComponentMemo = React.memo(ListBackupsComponent);
